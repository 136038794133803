import React from "react";
import Member from "./Member";
import "../styles/Team.css";

const Team = () => {
  return (
    <section id="team">
      <h1 className="team-h">Team</h1>

      <div className="team">
        <Member
          pfp="https://pbs.twimg.com/profile_images/1710026990303215616/2lZykg--_400x400.jpg"
          name="Meet"
          role="Web Developer"
          info="Web Developer and Co-Founder, I like to design websites in a way that is never done before, I also take months making website"
          link="https://twitter.com/meetmehta_"
        />
        <Member
          pfp="https://pbs.twimg.com/profile_images/1604860938179354624/yrhQz5X9_400x400.png"
          name="Adi"
          role="Founder"
          info="Marketing and Co-Founder, I always prefer Minimalistic Point to Point things, Let your actions talk rather than words"
          link="https://twitter.com/CryptooAdi"
        />
        <Member
          pfp="https://pbs.twimg.com/profile_images/1725485321436213248/Q7nMA0CW_400x400.jpg"
          name="Priyo"
          role="Manager"
          info="Backend and Co-Founder, There is nothing impossible in Code, and there is a place like 127.0.0.1 somewhere, Loli is my preference"
          link='https://twitter.com/PriyoNath_dev'
        />
      </div>
    </section>
  );
};

export default Team;
