import { useState } from "react";
import Form from "./components/Form";
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Team from "./components/Team";
import Overview from "./components/Overview";
import Contact from "./components/Contact";
import Motive from "./components/Motive";
import { useEffect } from "react";




function App() {
  const [movingStyle, setMovingStyle] = useState({});
  const [backgStyle, setBackgStyle] = useState();
  const [mainHeadStyle, setMainHeadStyle] = useState({});
  const [navbarStyle, setNavbarStyle] = useState({});
  const [formStyle, setFormStyle] = useState({});
  const [whitestyle, setwhitestyle] = useState({})
  
  
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    
    (new URL(window.location.href)).searchParams.forEach((x, y) =>
        document.getElementById(y).value = x)
  }, []);
  
  




  





  window.onload = ()=>{
    window.scrollTo({ top: 0, left: 0 });
  }

  function movestop() {
    window.scrollTo({ top: 0, left: 0 });
    setMovingStyle({
      right: `${getComputedStyle(document.getElementById("moving")).right}`,
      top: `${getComputedStyle(document.getElementById("moving")).top}`,
      animation: "expand 1500ms",
    });
    
    setBackgStyle({ display: "block" });
    setwhitestyle({ zIndex: '0' })
    setTimeout(() => {
      setwhitestyle({ display: "none" })
      setMovingStyle({ display: "none" });
    }, 1500);
    setTimeout(() => {
      setBackgStyle({ display: "block", translate: "0 0" });
      setTimeout(() => {
        setMainHeadStyle({
          background: "url(./images/background.svg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "100vh",
          backgroundPosition: "center",
        });
        setBackgStyle({ display: "none" });
        setFormStyle({ opacity: 1 });
        setNavbarStyle({ translate: "0 0" });

        document.body.style.overflowY = "scroll";
        document.body.style.background = "black";
      }, 1000);
    }, 1000);
  }

  document.onkeydown = (e) => {
    if (e.key === "F12") {
      e.preventDefault();
    }
    // if (e.ctrlKey && e.shiftKey && e.key == "I") {
    //   e.preventDefault();
    // }
    if (e.ctrlKey && e.shiftKey && e.key == "C") {
      e.preventDefault();
    }
    if (e.ctrlKey && e.shiftKey && e.key == "J") {
      e.preventDefault();
    }
    if (e.ctrlKey && e.key == "U") {
      e.preventDefault();
    }
  };

  return (
    <>
      <Navbar navbarStyle={navbarStyle} />
      <Header
        whitestyle={whitestyle}
        mainHeadStyle={mainHeadStyle}
        movingStyle={movingStyle}
        backgStyle={backgStyle}
        movestop={movestop}
        formStyle={formStyle}
      />
      <Overview />
      <Motive />
      <Team />
      <Contact />
    </>
  );
}

export default App;
