import "../styles/Motive.css";

import React from "react";

const Motive = () => {
  return (
    <section id="motive">
      <div className="motive-container">
        <h1>Motive</h1>
        <p>
        We aim to revolutionize the verification prospect by establishing proof of humanity mechanism on the blockchain, countering the prevalence of bots and AI, We are focused on Privacy full networks to help the Institutions get the proof of humanity without any data sharing, 
        </p>
      </div>
    </section>
  );
};

export default Motive;
