import React from "react";
import "../styles/Form.css";
import { useState } from "react";
const Form = ({ formStyle }) => {
  let submitted = false;



  const [creds, setCreds] = useState({ eaddress: "", refferedby: "" })

  const [hideb, sethideb] = useState({ display: 'none' })
  const [hidea, sethidea] = useState({})
  const [reffers, setreffers] = useState()
  const [hidebr, sethidebr] = useState({ display: 'none' })

  const [p, setp] = useState({ display: 'none' })
  const [p1, setp1] = useState({ display: 'none' })

  const [ip, setip] = useState({ display: 'none' })

  const [loading, setloading] = useState({ display: 'none' })
  const [wl, setwl] = useState({})


  const [rcode, setrcode] = useState()

  const { eaddress, refferedby } = creds




  fetch('https://api.ipify.org')
  .then((res)=> res.text())
  .then(ip => setip(ip)) 



  const RegisterSubmited = async function (e) {
    e.preventDefault()
    sethidebr({})
    sethidea({ display: 'none' })


    // setreffers("Loading... it might take a moment")
       setwl({background: "black", padding: "75px 0"})
       setloading({})


       setTimeout(() => {

        fetch('https://drri7v8gke.execute-api.ap-south-1.amazonaws.com/register', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams({
            eaddress: eaddress.toLowerCase(),
            refferedby: document.getElementById('refferedby').value,
            ip:ip
    
          })
        })
          .then(async (response) => response.json())
          .then(async json => {
            if (json.errors) {
              setloading({display: "none"})
              setwl({})
              // console.log(json)
              // showAlert(json.errors.msg, 'denger')
              setreffers(json.errors)
              console.log(json.errors)
            } else if (json.success) {
              
              // showAlert(json.success, 'success')
    
              setloading({display: "none"})
              setwl({})
    
    
              sethidea({ display: 'none' })
    
              sethideb({})
              sethidebr({})
    
    
              setrcode(json.refferalcode)
              setreffers(`Your referrals: ${json.reffers}`)
              setp1({})
              // setTimeout(() => {
    
              //   props.setSession(true)
              // }, 2000);
            }
    
    
    
          }).catch(() => {
            setloading({display: "none"})
            setwl({})
            setreffers("Too many requests, try again")
    
            setTimeout(() => {
              sethidea({})
              setreffers()
              sethidebr({ display: 'none' })
            }, 1500);
    
    
    
    
    
          })
        
       }, 1500);

 





  }

  const onChange = (e) => {
    setCreds({ ...creds, [e.target.name]: e.target.value })

  }




  return (
    <>

      <h3>MYSTIC</h3>
      <form onSubmit={RegisterSubmited}


        id="waitlist-form"
        style={formStyle}



      >


        <img className="paw" src="./images/paw.svg" alt="" />
        <div className="form-container">
        <img id="loading" src="./images/MeowLogoSVGo.png" alt="" style={loading} />
          <p style={hideb} class="HIDDEN">You have successfully joined the waitlist, refer friends to move up in the list</p>
          <p style={hidebr} className="rreffers">{reffers}</p>

          <p style={p1} className="rmessage" onClick={() => {
            navigator.clipboard.writeText(`https://mysticprotocol.com/?refferedby=${rcode}`);
            setp1({ display: "none" })
            setp({})
            setTimeout(() => {
              setp({ display: 'none' })
              setp1({})
            }, 1500);
          }}  >https://mysticprotocol.com/?refferedby=${rcode}</p>

          <p style={p} className="rmessage" > Referral coppied ✅ </p>


          <p style={hidea}> Join Waitlist</p>
          {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio, ut.</p> */}
          <input style={hidea} name="eaddress" id="eaddress" onChange={onChange}
            type="email"
            placeholder="Email"

            required
          />
          <input style={hidea} name="refferedby" id="refferedby" onChange={onChange}
            type="text"

          />

          <input style={hidea} type="submit" />
        </div>
      </form>
    </>
  );
};

export default Form;
