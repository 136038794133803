import React from "react";
import "../styles/Overview.css";

const Overview = () => {
  return (
    <section id="overview">
      <div className="overview">
        <div className="overview-container">
          <h1>MysticProtocol</h1>
          <p>
          Proof of Humanity and Privacy score on Aleo without getting your data mined by Institutions

          </p>
        </div>
      </div>
    </section>
  );
};

export default Overview;
