import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
const Member = ({ pfp, name, info, role, link }) => {
  return (
    <div
      className="member"
      style={{
        background: `url(${pfp})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="member-name">{name}<a style={{color: "white", marginLeft: "10px"} }href={link}><FontAwesomeIcon icon={faXTwitter} /></a></div>
      <div className="content-team">
        <div className="member-role">{role}</div>
        <div className="info">{info}</div>
      </div>
    </div>
  );
};

export default Member;
