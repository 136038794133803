import React from "react";
import "../styles/Navbar.css"

const Navbar = ({navbarStyle}) => {
  return (
    <nav style={navbarStyle}>
      <div className="nav-container">
        <img id="brandimg" src="./images/MeowLogoSVG.svg" alt="" />
        <div id="links">
          <a href="">MEOW</a>
          <a href="">MEOW</a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
