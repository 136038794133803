import React from "react";
import "../styles/Contact.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";


const Contact = () => {
  return (
    <section id="contact">
      <div className="footer">
        <img src="./images/MeowLogoSVG.svg" alt="" />
        <div className="links"><h1>Links</h1><a href="https://twitter.com/MysticProtocol" target="_blank" className="link">Twitter</a><a href="https://twitter.com/MysticProtocol" target="_blank" className="link">Discord</a><a href="https://twitter.com/MysticProtocol" className="link" target="_blank">Telegram</a></div>
        <div className="contact"><h1>Contact</h1><a href="https://twitter.com/MysticProtocol"><FontAwesomeIcon style={{marginTop: '15px'}} icon={faComments} /></a></div>
      </div>
    </section>
  );
};

export default Contact;
