import React from "react";
import "../styles/Header.css";
import Form from "./Form";

const Header = ({ whitestyle, movestop, movingStyle, backgStyle, mainHeadStyle, formStyle }) => {
  // alert(window.getComputedStyle(this.container))





  return (
    <header style={mainHeadStyle}>
      <div className="white" style={whitestyle}>

        <div className="whitespace">
          <img
            onLoad={() => {
              window.scrollTo({ top: 0, left: 0 });
              window.document.body.style.background = "black"
            }}
            id="moving"
            onClick={movestop}
            style={movingStyle}
            src="https://cdn.discordapp.com/attachments/899293202870304810/1174440786658590790/Untitled9_20231116013752.png?ex=65679a37&is=65552537&hm=7334b9ac6732f8b8e7853c2b006d9108da93b2eceb064688b151b5ce3e628efd&"
            alt=""
          />

          <form 

            id="waitlist-form"
            style={formStyle}


          >
            <img className="paw" src="./images/paw.svg" alt="" />
 
          </form>

        </div>
      </div>

      <div id="header-container">
        <img
          style={backgStyle}
          src="./images/background.svg"
          className="backs"
          id="background-head"
          alt=""
        />
        <Form formStyle={formStyle} />

      </div>
    </header>
  );
};

export default Header;
